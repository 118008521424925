import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismicPreviews'
import { Layout } from '../components/layout/Layout'
import { SliceZone } from '../components/SliceZone'
import SEO from '../components/seo'
import { PageTitle } from '../components/PageTitle'
import { PageContent } from '../components/PageContent'
import { Col } from 'react-bootstrap'
import PageTopRow from '../styledComponents/StyledPageTopRow'
import { useLocation } from '@reach/router'
import { useState, useEffect } from 'react'

const HomepageTemplate = ({ data }) => {

  const [scroll, setScroll] = useState(false);
  const [mobileScroll, setMobileScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 900);
      setMobileScroll(window.scrollY > 500);
    });
  }, []);

  const { pathname } = useLocation();
  console.log('Pathname', pathname)

  if (!data) return null
  
  // console.log('homepage data', data)
  const homepage = data.prismicHomepage || {}
  const topMenu = data.prismicTopMenu || {}
  const websiteDaten = data.prismicWebsiteDaten || {}

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages
  }

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc} websiteDaten={websiteDaten.data} pathname={pathname} scroll={scroll} mobileScroll={mobileScroll}>
      <SEO
        title={homepage.data.site_meta_title}
        description={homepage.data.site_meta_description}
        article
      />
      {homepage.data.site_title.raw[0].text !== "" ?
        <PageTopRow>
          <Col>
            <PageTitle pageTitle={homepage.data.site_title.raw} />
            <PageContent pageContent={homepage.data.site_content.raw} />
          </Col>
         </PageTopRow>
        : null
      }
      <SliceZone slices={homepage.data.body} />
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        site_title {
          raw
        }
        site_content {
          raw
        }
        site_meta_description
        site_meta_title
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...PrismicHomepageDataBodyHero
          ...PrismicHomepageDataBodyCallToActionGrid
          ...PrismicHomepageDataBodyAnrisstext
          ...PrismicHomepageDataBodyCarousel
          ...PrismicHomepageDataBodyTextBlock
          #...HomepageDataBodyEmailSignup
          #...HomepageDataBodyFullWidthImage
          #...HomepageDataBodyHeadlineWithButton
          #...HomepageDataBodyInfoWithImage
          #...HomepageDataBodyTextInfo
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    prismicWebsiteDaten(lang: { eq: $lang }) {
      ...WebsiteDatenFragment
    }
  }
`

export default withPrismicPreview(HomepageTemplate, repositoryConfigs)
